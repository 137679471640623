<template>
    <header class="heading-section" loading="lazy">
        <div v-if="showMarquee && siteSettings && siteSettings.announcement && siteSettings.announcement.msg" class="moving-text">
            <marquee behavior="" direction="left">
                <span>{{ siteSettings.announcement.msg }}</span>
            </marquee>
            <button @click="showMarquee = false" type="button" class="close-moving-text">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
        <nav class="navbar navbar-expand-lg ">
            <div class="container-fluid">
                <a class="btn-nav-menu-bar" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                    aria-controls="offcanvasExample">
                    <img loading="lazy" src="@/assets/images/menu.webp" alt="" class="menu-toggle-btn">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <div class="fair-play-header">
                        <ul class="navbar-nav">
                            <li class="nav-item logo">
                                <!-- <router-link to="/" class="nav-link active" aria-current="page"><img
                                        src="@/assets/images/fair-play-icons.webp" alt=""></router-link> -->
                                <router-link to="/">
                                    <img :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo" alt="logo" class="web-logo" />
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="News"
                                    class="cmn-tooltops-btn">
                                    <i class="bi bi-newspaper"></i>
                                </a>
                                <div class="news"><span>{{ translatedLangData('news','news')}}</span></div>
                            </li>
                            <li class="nav-item">
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Refresh"
                                    class="cmn-tooltops-btn">
                                    <i class="bi bi-arrow-clockwise"></i>
                                </a>
                                <div class="news"><span>{{ translatedLangData('refresh','Refresh')}}</span></div>
                            </li>
                            <li class="nav-item">
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Blogs"
                                    class="cmn-tooltops-btn">
                                    <i class="bi bi-newspaper"></i>
                                </a>
                                <div class="news"><span>{{ translatedLangData('blogs','Blogs')}}</span></div>
                            </li>
                        </ul>
                    </div>



                    <div v-if="checkIsLogin()" class="fair-play-search-sec last-btn">
                        <div class="search-bar">
                            <div class="search-here">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="text-warning"><i class="fa fa-search"></i></div>
                                        <input type="search" placeholder="Search for a Sports, Games or Teams"
                                            aria-describedby="button-addon2" class="form-control border-0 ">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="leg-option">
                            <select class="form-select" aria-label="Default select example">
                                <option selected="">Tamil (தமிழ்)</option>
                                <option value="1">Bengali (বাংলা)</option>
                                <option value="2">English (English) </option>
                                <option value="3">Malayalam (മലയാളം) </option>
                                <option value="4">Marathi (मराठी)</option>
                                <option value="5">Kannada (ಕನ್ನಡ) </option>
                                <option value="6">Gujarati (ગુજરાતી) </option>
                                <option value="7">Hindi (हिन्दी) </option>
                                <option value="7">Telugu (తెలుగు) </option>
                            </select>
                        </div> -->
                        <div class="open-bets">
                            <div class="side-btn">
                                <router-link to="/bets" class="bets active"><span>{{ translatedLangData('open-bets','Open Bets')}}</span></router-link>
                                <a href="Javascript:void(0);" @click.prevent="getWalletGetway()" class="bets depo-bet-btn"><img
                                        src="@/assets/images/Deposit_Now_Icon.webp" class="deposit-image" alt=""><span> {{ translatedLangData('deposit','Deposit')}}
                                    </span></a>
                                <a href="Javascript:void(0);" @click.prevent="getWalletGetway()" class="wallet"><span><i class="fa-solid fa-wallet"></i> 
                                        {{ userData?.balance }}</span></a>
                                <a data-bs-toggle="offcanvas" href="#offcanvasprofile" role="button" aria-controls="offcanvasprofile" class="profile">
                                    <span class="user-rgt-side">
                                        <i class="fa-solid fa-user"></i>
                                        <figure class="hide account-profile-txt">  {{ translatedLangData('profile','Profile')}}</figure>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div v-else class="fair-play-search-sec">
                        <div class="open-menu">
                            <div class="nav-item dropdown">
                                <select class="form-select language" aria-label="Default select example">
                                    <option selected>English</option>
                                    <option value="1">Hindi</option>
                                    <option value="2">Urdu</option>
                                    <option value="3">Gujarati</option>
                                </select>
                            </div>
                            <div class="nav-item dropdown">
                                <select class="form-select country-code" aria-label="Default select example">
                                    <option selected> IN</option>
                                    <option value="1"> AE</option>
                                </select>
                            </div>
                            <div class="nav-item">
                                <router-link to="/login" class="btn login-btn">{{ translatedLangData('login','Login')}}</router-link>
                            </div>
                            <div class="nav-item">
                                <router-link to="/sign-up" class="btn register-btn">{{ translatedLangData('register','Register')}}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { getUtcTimeStampFromDate, convertUTCDateToLocalDate, getSubtractedDaysDate } from '@/shared/utils/date-time';
import * as types from '@/modules/bets/utils/constants'
import moment from 'moment'
import api from '../../services/api'
import * as apiName from '../../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";

export default {
    name: 'Header',
    inject:['translatedLangData'],
    data() {
        return {
            showMarquee: true,
            userData: null,
            filterOption: {
                betType: types.BET_TYPES[0].id,
                gameType: types.GAME_TYPES[0].id,
                gameSubType: types.SPORTS_SUB_TYPES[0].id,
                fromDate: moment(new Date(getSubtractedDaysDate(new Date(), 2))).format('YYYY-MM-DD'),
                toDate: moment(new Date()).format('YYYY-MM-DD')
            },
            pageEntries: 50,
            pageNo: 1,
            openBetsList: null,
        }
    },
    computed: {
         ...mapGetters({
            siteSettings: 'siteSettings' , stateUser : 'stateUser'
        })
      },
    mounted() {
        this.userData = this.$store?.getters?.stateUser;        
    },
    methods: {
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        getOpenBetsServiceCall(type) {
            this.loading = true;
            console.log(type)

            let data = {
                "type": '1',
                "page_entries": this.pageEntries,
                "fromtimestamp": getUtcTimeStampFromDate(new Date(this.filterOption.fromDate)),
                "totimestamp": (getUtcTimeStampFromDate(new Date(this.filterOption.toDate)) + 86399)
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }

            api.post(apiName.GET_MY_BETS + '?page=' + this.pageNo, data ,{headers}).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {

                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response?.data?.debug[0]);
                        }
                        else {
                            // this.serviceData = response?.data?.data;
                            // this.totalPage = this.serviceData?.last_page;
                                this.openBetsList = response?.data?.data?.data;
                                this.$store.dispatch('SetOpenBets',this.openBetsList )
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        getWalletGetway() {
            if(this.siteSettings.business_type == 2) {
                this.$router.push({name: 'wallet-gateway'});
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal',message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal',message);
        },
    }
}

</script>