<template>
    <!-- show more tab modal pop-up -->
    <div class="more-tab-show-m-view">

<div class="modal fade" id="more-item-tabs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="show-more-tabs-modal-body">
            <ul>
                <li  v-for="(sport, n_index) in non_custom_sports" :key="c_index" data-bs-dismiss="modal">
                    <router-link :to="'/sports/' + sport.slug + '/' + sport.id" >
                        <div>
                            <img  v-if="sport.id == 4" src="@/assets/images/cricket.5c05f66.webp" :alt="sport.name">
                            <img v-if="sport.id == 1" src="@/assets/images/soccer.9f718cc.webp" :alt="sport.name">
                            <img v-if="sport.id == 2" src="@/assets/images/tennis.fc30791.webp" :alt="sport.name">
                        </div>
                        <div>
                            <span>{{ sport.id == 1 ? translatedLangData('soccer','Soccer' ) :translatedLangData(sport.name,sport.name ) }}</span>
                        </div>
                </router-link>
                </li>

                <li v-for="(c_sport, c_index) in custom_sports" :key="c_index" data-bs-dismiss="modal">
                <router-link :to="'/sports/' + c_sport.slug.trim() + '/' + c_sport.id">
                    <div>
                        <img v-if="c_sport.slug.trim() === 'icc-world'" src="@/assets/images/icc-world.webp" :alt="c_sport.name">
                        <img v-else-if="c_sport.slug.trim() === 'lok-sabha-2024'" src="@/assets/images/election.webp" :alt="c_sport.name">
                        <img v-else src="@/assets/images/game2.webp" :alt="c_sport.name">
                    </div>
                    <div>
                        <span>{{ c_sport.slug }}</span>
                    </div>
                </router-link>
            </li>

                <li>
                    <a @click="gotoSportsBook('sr:sport:31')" data-bs-dismiss="modal">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-3.webp" alt="">
                        </div>
                        <div>
                            <span>{{ translatedLangData('badminton','Badminton') }}</span>
                        </div>
                    </a>
                </li>
                
                <!-- <li>
                    <a @click="gotoSportsBook('sr:sport:2')">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-4.webp" alt="">
                        </div>
                        <div>
                            <span>Golf</span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/horse.webp" alt="">
                        </div>
                        <div>
                            <span>Horse Racing</span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/greyhound.webp" alt="">
                        </div>
                        <div>
                            <span>Greyhound Racing</span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/global-network.webp" alt="">
                        </div>
                        <div>
                            <span>Virtual Sports</span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-5.webp" alt="">
                        </div>
                        <div>
                            <span>Counter Strike</span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/gold-pot.webp" alt="">
                        </div>
                        <div>
                            <span>Matka</span>
                        </div>
                    </a>
                </li> -->

                <li>
                    <a @click="gotoSportsBook('sr:sport:3')" data-bs-dismiss="modal">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-6.webp" alt="">
                        </div>
                        <div>
                            <span>
                                {{ translatedLangData('baseball','Baseball') }}
                            </span>
                        </div>
                    </a>
                </li>

                <li>
                    <a @click="gotoSportsBook('sr:sport:12')" data-bs-dismiss="modal">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-7.webp" alt="">
                        </div>
                        <div>
                            <span>
                                {{ translatedLangData('rugby','Rugby') }}
                            </span>
                        </div>
                    </a>
                </li>

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-8.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Boxing
                            </span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-10.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Fifa
                            </span>
                        </div>
                    </a>
                </li> -->

                <li>
                    <a @click="gotoSportsBook('sr:sport:23')" data-bs-dismiss="modal">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-11.webp" alt="">
                        </div>
                        <div>
                            <span>
                                {{ translatedLangData('volleyball','Volleyball') }}
                            </span>
                        </div>
                    </a>
                </li>

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-12.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Fifa22-volta-
                            </span>
                        </div>
                    </a>
                </li> -->


                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-13.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Dota 2
                            </span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-14.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Ecricket
                            </span>
                        </div>
                    </a>
                </li> -->

                <li>
                    <a @click="gotoSportsBook('sr:sport:4')" data-bs-dismiss="modal">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-15.webp" alt="">
                        </div>
                        <div>
                            <span>
                                {{ translatedLangData('ice-hockey','Ice Hockey') }}
                            </span>
                        </div>
                    </a>
                </li>

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-16.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Handball
                            </span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-17.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Cycling
                            </span>
                        </div>
                    </a>
                </li> -->

                <li>
                    <a @click="gotoSportsBook('sr:sport:22')" data-bs-dismiss="modal">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-18.webp" alt="">
                        </div>
                        <div>
                            <span>
                                {{ translatedLangData('darts','Darts') }}
                            </span>
                        </div>
                    </a>
                </li>
                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-19.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Efighting
                            </span>
                        </div>
                    </a>
                </li> -->
                <li>
                    <a @click="gotoSportsBook('sr:sport:16')" data-bs-dismiss="modal">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-20.webp" alt="">
                        </div>
                        <div>
                            <span>
                                {{ translatedLangData('american-football','American Football') }}
                            </span>
                        </div>
                    </a>
                </li>

                <li>
                    <a @click="gotoSportsBook('sr:sport:19')" data-bs-dismiss="modal">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-21.webp" alt="">
                        </div>
                        <div>
                            <span>
                                {{ translatedLangData('snooker','Snooker') }}
                            </span>
                        </div>
                    </a>
                </li>

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-22.webp" alt="">
                        </div>
                        <div>
                            <span>
                                NBA2k19
                            </span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-23.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Waterpolo
                            </span>
                        </div>
                    </a>
                </li> -->

                <!-- <li>
                    <a href="#">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-24.webp" alt="">
                        </div>
                        <div>
                            <span>
                                Bowls
                            </span>
                        </div>
                    </a>
                </li> -->

                <li>
                    <a @click="gotoSportsBook('sr:sport:20')" data-bs-dismiss="modal">
                        <div>
                            <img loading="lazy" src="@/assets/images/game-25.webp" alt="">
                        </div>
                        <div>
                            <span>
                                {{ translatedLangData('table-tennis','Table Tennis') }}
                            </span>
                        </div>
                    </a>
                </li>

                <li>
                    <router-link to="/matka" >
                        <div data-bs-dismiss="modal">
                            <div>
                                <img loading="lazy" src="@/assets/images/matka-Layer_1.svg" alt="">
                            </div>
                            <div>
                                <span>
                                    {{ translatedLangData('matka','Matka') }}
                                </span>
                            </div>
                        </div>
                    </router-link>
                </li>


            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: "MobileSportSelection",
    inject:['translatedLangData'],
    props:['non_custom_sports', 'custom_sports'],
    data(){
        return{
            sportsBookGames:[
            {
                "id": 'sr:sport:21',
                "name": 'Criket',
            },
            ]
        }
    },
    methods: {
        gotoSportsBook(id){
            this.$router.push({ name: 'sports-book-wise', params: { id: id } })
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        }
    }
}
</script>