<template>
    <div class="modal fade change-lang-modal" id="language_selection_pop_up" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>{{ translatedLangData('change-your-language','Change your language')}}</h3>
                    </div>
                    <img loading="lazy" data-bs-dismiss="modal" class="gp-closeButton" src="@/assets/images/close-language.webp" alt="">
                </div>
                <div class="modal-body">
                    <section class="sub-header-wrapper">

                        <div class="langauage-content">
                            <div class="search-lang-input">
                                <input type="search" :placeholder="translatedLangData('search-key','Search')" v-model="searchTerm"  class="form-control"> <button class="btn link-btn">
                                    <img src="@/assets/images/search-grey-icon.webp" alt="search">
                                </button>
                            </div>
                            <div class="custom-language-list">
                                <ul>
                                    <li v-for="item in filteredLanguages" :key="item.lang_code"
                                        :class="{ 'active': selectedValue.lang_code == item.lang_code }">
                                        <div class="form-check" @click="this.selectedValue = item">
                                            <label class="form-check-label">
                                                {{ item.lang_name }}
                                            </label>
                                        </div>
                                    </li>            
                                </ul>
                            </div>
                            </div>
                            <div class="two-btns">
                                <ul class="cmn-ul-list">
                                    <li><button class="btn thm-bor-but" data-bs-dismiss="modal">{{ translatedLangData('close','Close')}}</button></li>
                                    <li><button class="btn thm-but"  @click="selectedlanguage">{{ translatedLangData('update','Update')}}</button></li>
                                </ul> 
                            </div>
                            <!-- <div id="app_1"> </div> -->
                    </section>
                </div>
            </div>
        </div>
    </div>

    <div ref="iframe_pop_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#iframe_pop_modal">
    </div>

</template>
<script>
import LanguageService from '@/shared/services/language-service.js'


export default {
    name: 'GamesPop1',
    inject:['translatedLangData'],
    data() {
        return {
            openIframeModal: false,
            selectedValue: '',
            searchTerm: ''
        }
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        languagesData() {
            return this.$store.getters.languagesData;
        },
        filteredLanguages() {
            if(this.searchTerm) {
                const searchTermLower = this.searchTerm.toLowerCase();
                return this.languagesData.filter(item => item.lang_name.toLowerCase().includes(searchTermLower));
            }
            return this.languagesData
        },

    },
    methods: {
        yesAgreeMethodCall() {
            this.openIframeModal = true;
            this.$refs.iframe_pop_modal.click();
        },
        closeModal() {
            this.openIframeModal = false
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        async selectedlanguage() {
            if (this.selectedValue && this.selectedValue.lang_code === 'en') {
            localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_code);
            this.$parent.langData = "";
            this.showSuccessModalFunc('Language Updated Successfully');
            document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
        } else if (this.selectedValue) {
            const aws_url_language = this.siteSettings?.aws_url + '/' + this.selectedValue?.lang_url;
            this.$parent.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
            if (this.$parent.langData) {
                localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
                this.showSuccessModalFunc('Language Updated Successfully');
                document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
            } else {
                localStorage.removeItem('selectedLanguageUrl');
                this.showErrorModalFunc('language Data Is Not Available');
            }
        }
        },
    },
    components: {
    }
}
</script>

<style scoped>

.modal-content{
    background-color: transparent !important;
}
</style>