
export const USER_PROFILE='api/user-profile'
export const LOGOUT_API= '/api/logout'
export const AUTH_API= '/api/auth-status'

export const SITE_SETTINGS='api/site-settings';
export const AUTH_STATUS='api/auth-status';
export const GET_FAVOURITE_LIST='api/favorite-list';
export const WALLET_BALANCE='wallet';
export const AUTH_REFRESH='api/auth-refresh';
export const GET_SPORTS= 'v1/sports/management/getSport';
export const GET_MY_BETS='api/lotus/get-my-bets';
export const SPORTS_COUNT='api/v1/events/count';
export const GET_SITE_RULES='api/get-site-rules';
export const GET_BONUS_LOGS= 'api/get-bonus-log';
export const SUBSCRIBE_NOTIFICATION= 'api/subscribe-notification';
export const NOTIFICATIONS_LIST= 'get/all/notification/history';