<template>
    <div class="app-footer-sec" v-if="showFooter">
			<div class="mobile-footer">
				<ul>
					<li class="mf-navbar">
						<router-link to="/live-card">
							<img loading="lazy" src="@/assets/images/live-cards.webp" alt="">
							<span>{{ translatedLangData('live-card','Live Card')}}</span>
						</router-link>
					</li>
					<li class="mf-navbar">
						<router-link to="/live-casino">
							<img loading="lazy" src="@/assets/images/casino-live.webp" alt="">
							<span>{{ translatedLangData('live-casino','Live Casino')}}</span>
						</router-link>
					</li>
					<li class="mf-navbar">
						<router-link to="/slot-games">
							<img loading="lazy" src="@/assets/images/slot-games.ccf3217.webp" alt="">
							<span>{{ translatedLangData('slot-games','Slot games')}}</span>
						</router-link>
					</li>
					<li>
						<router-link to="/crash-games">
							<img loading="lazy" src="@/assets/images/crash-img.webp" alt="">
							<span>{{ translatedLangData('crash-games','Crash games')}}</span>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
		<div class="hide-show-icon" :style="!showFooter ? 'bottom : 0 !important': ''">
			<div class="bottom-arrow">
				<button type="button" class="footer-click-btn" @click="showFooter = !showFooter">
					<i v-if="showFooter" class="fa-solid fa-angle-down"></i>
					<i v-else class="fa-solid fa-angle-up"></i>
				</button>
			</div>
		</div>
</template>

<script>
export default {
    name: "MobileFooter",
		data(){
			return{
			showFooter:true
		}
	},
	inject:['translatedLangData'],

}
</script>