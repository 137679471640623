<template>
    <div class="modal fade game-point-note-modal" id="static_games_pop_1" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border: none;background-color: transparent;">
                <div class="modal-body">
                    <section class="sub-header-wrapper">
                        <div class="gp-content">
                            <img class="gp-img" src="@/assets/images/slotsCasinoPopUp.webp" alt="">
                            <img data-bs-dismiss="modal" class="gp-closeButton" src="@/assets/images/closeButton.webp" alt="">
                            <div class="gp-title">PLEASE NOTE</div>
                            <div class="gp-descr">(1 Point =  100)</div>
                            <div class="gp-welcome">Welcome to your new casino lobby</div>
                            <div class="gp-checkout">Checkout the exciting Live Casino tables, Slots and much more, upto
                                200+ game and counting...</div>
                            <div @click="yesAgreeMethodCall()" class="gp-button thm-but ok-agree-btn">OK, I AGREE !</div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>

    <div ref="iframe_pop_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#iframe_pop_modal">
    </div>

    <!-- Game Play Modal Start -->
    <div class="modal fade game-play-modal" data-bs-backdrop="static" data-bs-keyboard="false" id="iframe_pop_modal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-fullscreen" style="max-width: 100%;">
            <IFrameModal @close-modal="openIframeModal=false" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"  v-if="openIframeModal" />
        </div>

    </div>
</template>
<script>

import IFrameModal from '@/modules/games/components/IFrameModal.vue';

export default {
    name: 'GamesPop1',
    data() {
        return {
            openIframeModal: false,
        }
    },
    methods: {
        yesAgreeMethodCall() {
            console.log('yessd')
            this.openIframeModal = true;
            this.$refs.iframe_pop_modal.click();
        },
        closeModal() {
            this.openIframeModal = false
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    },
    components: {
        IFrameModal
    }
}
</script>