<template>
    <div class="balance-deposit-show">
		<div class="modal fade" id="LowBalanceModal" tabindex="-1" aria-labelledby="LowBalanceModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
			  <div class="modal-content">
				<div class="modal-header">
				  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="deposit-balnace-body-container">
					<div class="modal-body">
						<div class="deposit-icon">
							<figure>
								<img loading="lazy" src="@/assets/images/wallet.webp" alt="">
							</figure>
						</div>

						<div class="balance-show-h">
							<h3>Looks like your balance is too low</h3>
							<h5>Deposit balance to continue playing.</h5>
						</div>

						<div class="depo-balance-btn" v-if="siteSettings?.business_type == 2">
							<button type="button" @click="goToDeposit()" data-bs-dismiss="modal">Deposit Balance </button>
						</div>
					</div>
				</div>
			  </div>
			</div>
		</div>
	 </div>
</template>

<script>
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";

export default {
    name: 'LowBalanceRemainder',
	computed: {
		siteSettings() {
			return this.$store.getters.siteSettings
		}
	},
    methods: {
        goToDeposit() {
            this.$router.push({name: 'wallet-gateway'});
        },

    },
}
</script>